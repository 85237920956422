import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {Divider, Skeleton, styled} from '@mui/material';
import Box from '@mui/material/Box';
import InboxLeftSideSkeleton from '../../LoadingView/InboxLeftSideSkeleton';
import InboxLeftSideItem from './InboxLeftSideItem';
import InboxLeftBulkActions from './InboxLeftBulkActions';
import InboxSubmissionSearch from './InboxSubmissionSearch';
import GlobalEmptyPage from '../../../common/GlobalEmptyPage/GlobalEmptyPage';
import removeFalsyObjectProperty from '../../../helpers/utils/removeFalsyObjectProperty';
import {
  getFormSubmissionData,
  getMoreFormSubmissionData
} from '../../../state/features/formSubmission/formSubmissionSlice';
import {
  selectFormSubmissionData,
  selectInboxFilters
} from '../../../state/features/formSubmission/formSubmissionSelector';

const InboxSubLeftBox = styled(Box)(({theme}) => ({
  maxWidth: '413px',
  width: '100%',
  flexShrink: 0,
  background: theme.palette.common.white,
  height: '100%',
  [theme.breakpoints.down('lg')]: {
    maxWidth: ' 100%'
  }
}));

const InboxLeftSide = () => {
  const dispatch = useDispatch();
  const {formId} = useParams();
  const {
    data: submissionList,
    isLoading,
    page,
    limit,
    count,
    renderId
  } = useSelector(selectFormSubmissionData);
  const {searchText, startDate, endDate} = useSelector(selectInboxFilters);
  const [hasMore, setHasMore] = useState(true);

  useEffect(() => {
    if (renderId) {
      const reqObj = removeFalsyObjectProperty({
        formId: formId,
        page: page,
        limit: limit,
        searchText: searchText,
        startDate: startDate,
        endDate: endDate
      });
      dispatch(getFormSubmissionData(reqObj));
    } else {
      const reqObj = removeFalsyObjectProperty({
        formId: formId,
        page: 1,
        limit: limit,
        searchText: searchText,
        startDate: startDate,
        endDate: endDate
      });
      dispatch(getFormSubmissionData(reqObj));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, startDate, endDate, formId, renderId]);

  const fetchMore = () => {
    const reqObj = removeFalsyObjectProperty({
      formId: formId,
      page: page + 1,
      limit: limit,
      searchText: searchText,
      startDate: startDate,
      endDate: endDate
    });
    dispatch(getMoreFormSubmissionData(reqObj));
  };


  useEffect(() => {
    if (count > 0) {
      const has = Math.ceil(count / limit) > page;
      setHasMore(has);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [count, page]);

  // decide what to render
  let content = null;
  if (isLoading) {
    content = <InboxLeftSideSkeleton />;
  } else if (!isLoading && submissionList?.length === 0) {
    content = <GlobalEmptyPage title={''} />;
  } else if (!isLoading && submissionList?.length > 0) {
    content = (
      <Box
        sx={{
          margin: '0px 4px 4px 0',
          overflowX: 'hidden',
          height: 'calc(100% - 150px)',
          maxHeight:680
        }}
        className="fbp_has_scroll"
        id={'formSubmissionListScrollBar'}
      >
        <InfiniteScroll
          dataLength={submissionList.length}
          next={fetchMore}
          hasMore={hasMore}
          loader={
            <Box sx={{px: 1}}>
              <Skeleton variant="text" sx={{marginTop: '12px'}} />
              <Skeleton variant="text" width={'200px'} />
              <Skeleton variant="text" width={'300px'} />
            </Box>
          }
          scrollableTarget="formSubmissionListScrollBar"
        >
          {submissionList.map(submissionData => (
            <InboxLeftSideItem key={submissionData.id} submissionData={submissionData} />
          ))}
        </InfiniteScroll>
      </Box>
    );
  }

  return (
    <InboxSubLeftBox>
      <Box sx={{p: 2}}>
        <InboxSubmissionSearch />
      </Box>
      <Divider />

      <InboxLeftBulkActions />
      {content}
    </InboxSubLeftBox>
  );
};

export default InboxLeftSide;
