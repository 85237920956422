function responseToIframe() {
  if (window.parent !== window) {
    window.parent.postMessage(
      {
        message: 'FORM_BUILDER_EMPTY_RESPONSE',
        data: window.location.href
      },
      '*'
    );
  }
}

export default responseToIframe;
