import React from 'react';
import {Box, Checkbox, FormControl, FormControlLabel, RadioGroup, Stack, styled} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import {FormSubtext} from '../../../common/Form-UI/FormSubtext';
import {FormSubtextError} from '../../../common/Form-UI/FormSubtextError';
import {FormText} from '../../../common/Form-UI/FormText';
import {ConnectPublicForm} from '../../PublicView/ConnectPublicForm';
import withFieldChange from '../../../hoc/withFieldChange';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from "../../../common/Icons/RequireIcon";

const FormControlLabelStyled = styled(FormControlLabel)(({theme}) => ({
  '&.MuiFormControlLabel-root': {
    margin: '0',
    '& .MuiCheckbox-root': {
      padding: theme.spacing(0.5),
      color: 'var(--fbp-theming-font-color)'
    }
  }
}));

const A2p10dlcComponent = ({field, onChange, editMode}) => {
  const labelAlignmentClass =
    field.labelAlignment === 'left'
      ? 'labelAlignLeft'
      : field.labelAlignment === 'right'
      ? 'labelAlignRight'
      : '';

  const onChangeValue = (value, method, formOption) => {
    if (!field.readOnly && !editMode) {
      method?.setValue(field.id, value);
      onChange({value});
      if (formOption.formState.isSubmitted) {
        method?.trigger();
      }
    }
  };

  return (
    <>
      <Box mb={1} className={labelAlignmentClass}>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}</FormLabel>
          {field.required && field.addCheckbox && <RequireIcon />}
        </Stack>

        <Stack direction="column" spacing={1} sx={{width: '100%'}}>
          <ConnectPublicForm
            name={field.id}
            value={field.value}
            rules={field.required && field.addCheckbox ? {required: 'This field is required'} : {}}
            render={(formOption, formMethods) => (
              <>
                <Box sx={{width: '100%'}}>
                  <FormControl component="fieldset" sx={{width: '100%'}}>
                    <RadioGroup
                      sx={{rowGap: '12px'}}
                      aria-label="checkbox"
                      name="checkboxComponent"
                    >
                      <Stack
                        direction="row"
                        alignItems={'flex-start'}
                        sx={{width: '100%', gap: '8px'}}
                        onClick={() => onChangeValue(!field.value, formMethods, formOption)}
                      >
                        {field.addCheckbox && (
                          <FormControlLabelStyled
                            control={
                              <Checkbox checked={field.value} onClick={e => e.preventDefault()} />
                            }
                          />
                        )}
                        <FormText
                          sx={{whiteSpace: 'normal',fontSize:'8pt',color:'initial',lineHeight:1}}
                          dangerouslySetInnerHTML={{__html: field.checkboxLabel}}
                        />
                      </Stack>
                    </RadioGroup>
                  </FormControl>
                </Box>

                {formOption.fieldState.error?.message ? (
                  <FormSubtextError mt={1}>{formOption.fieldState.error.message}</FormSubtextError>
                ) : (
                  <FormSubtext mt={1}>{field.helperText}</FormSubtext>
                )}
              </>
            )}
          />
        </Stack>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </>
  );
};

export default withFieldChange(A2p10dlcComponent);
