import React from 'react';
import {useSelector} from 'react-redux';
import A2p10dlcGeneral from './A2p10dlcGeneral';
import A2p10dlcOptions from './A2p10dlcOptions';
import A2p10dlcAdvanced from './A2p10dlcAdvanced';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const A2p10dlcRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <A2p10dlcGeneral field={field} />
      </CustomTab>
      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <A2p10dlcOptions field={field} />
        </CustomTab>
      )}
      <CustomTab title="Advanced">
        <A2p10dlcAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default A2p10dlcRightPanel;
