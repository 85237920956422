import React from 'react';
import {useSelector} from 'react-redux';
import DisclaimerGeneral from './DisclaimerGeneral';
import DisclaimerOptions from './DisclaimerOptions';
import DisclaimerAdvanced from './DisclaimerAdvanced';
import CustomTabs, {CustomTab} from '../../../common/Tab/CustomTabs';

const DisclaimerRightPanel = ({field}) => {
  const {theme} = useSelector(state => state.builderSetting);

  return (
    <CustomTabs className=" fbpFormBuilderRight">
      <CustomTab title="General">
        <DisclaimerGeneral field={field} />
      </CustomTab>
      {theme.layout === 'classic' && (
        <CustomTab title="Options">
          <DisclaimerOptions field={field} />
        </CustomTab>
      )}
      <CustomTab title="Advanced">
        <DisclaimerAdvanced field={field} />
      </CustomTab>
    </CustomTabs>
  );
};

export default DisclaimerRightPanel;
