import React, { useState } from 'react';
import {Box, Stack} from '@mui/material';
import {FormDescription} from '../../../common/Form-UI/FormDescription';
import {FormCardLabel} from '../../../common/Form-UI/FormCardLabel';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import RequireIcon from '../../../common/Icons/RequireIcon';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import { useIsMounted } from '../../../hooks/IsMounted';
import useDelayCallback from '../../../hooks/useDelayCallback';

const CardViewAddressComponent = ({field, editMode, onChange}) => {

  const isMounted = useIsMounted();
  const [localValue, setLocalValue] = useState({});

  useDelayCallback(() => {
    if(isMounted) {
      onChange(localValue);
    }
  },[localValue],20);

  const handleUpdateAddress = (value) => {
    setLocalValue({...localValue, ...value})
  }

  return (
    <>
      {/*<CardStep pageNo={pageNo} totalPage={totalPage} />*/}
      <Box>
        <Stack direction="column" mb={4}>
          <Stack direction="row" alignItems="center">
             <FormCardLabel>{field.label}{field.required && <RequireIcon variant="card" />}</FormCardLabel>
          </Stack>
          <FormDescription>{field.description}</FormDescription>
        </Stack>
        <Stack direction="column" sx={{position: 'relative', gap: '20px', sm: {gap: '4px'}}}>
          {field.streetAddressEnabled && (
            <Box sx={{width: '100%'}}>
              <FormInput
                name={field.id + 1}
                value={field.streetAddressValue}
                placeholder={field.streetAddressPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({streetAddressValue: e.target.value})}
                helperTextProps={{
                  text: field.streetAddressHelperText
                }}
                rules={field.required ? {required: 'Street address is required'} : {}}
              />
            </Box>
          )}

          {field.streetAddress2Enabled && (
            <Box sx={{width: '100%'}}>
              <FormInput
                name={field.id + 2}
                value={field.streetAddress2Value}
                placeholder={field.streetAddress2Placeholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({streetAddress2Value: e.target.value})}
                helperTextProps={{
                  text: field.streetAddress2HelperText
                }}
                rules={field.required ? {required: 'Street address 2 is required'} : {}}
              />
            </Box>
          )}

          {field.cityEnabled && (
            <Box sx={{width: '100%'}}>
              <FormInput
                name={field.id + 3}
                value={field.cityValue}
                placeholder={field.cityPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({cityValue: e.target.value})}
                helperTextProps={{
                  text: field.cityHelperText
                }}
                rules={field.required ? {required: 'City is required'} : {}}
              />
            </Box>
          )}

          {field.stateEnabled && (
            <Box sx={{width: '100%'}}>
              <FormInput
                name={field.id + 4}
                value={field.stateValue}
                placeholder={field.statePlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({stateValue: e.target.value})}
                helperTextProps={{
                  text: field.stateHelperText
                }}
                rules={field.required ? {required: 'State is required'} : {}}
              />
            </Box>
          )}

          {field.zipEnabled && (
            <Box sx={{width: '100%'}}>
              <FormInput
                name={field.id + 5}
                value={field.zipValue}
                placeholder={field.zipPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({zipValue: e.target.value})}
                helperTextProps={{
                  text: field.zipHelperText
                }}
                rules={field.required ? {required: 'Zip is required'} : {}}
              />
            </Box>
          )}
        </Stack>

        {field.hidden && <HiddenFieldAlert />}
      </Box>
    </>
  );
};

export default withFieldChange(CardViewAddressComponent);
