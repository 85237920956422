import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  setting: {
    mappings: []
  }
};

const fieldMappingSlice = createSlice({
  name: 'fieldMapping',
  initialState: initialState,
  reducers: {
    fetchFieldMapping: (state, action) => {
      state.isLoading = true;
      state.setting.mappings = [];
    },
    fetchFieldMappingSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload) {
        state.setting.mappings = action.payload;
      }
    },
    updateFieldMapping: () => {}
  }
});

export const {fetchFieldMapping, fetchFieldMappingSuccess, updateFieldMapping} =
  fieldMappingSlice.actions;

export default fieldMappingSlice.reducer;
