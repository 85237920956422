import React from 'react';
import {useSelector} from 'react-redux';
import {Typography} from '@mui/material';
import HrefLink from '../../../common/Link/HrefLink';
import {FormHeaderWrapper, HeaderRightSide, LogoImageBoxinForm} from './HeaderStyles';
import {selectFormFooterData} from '../../../state/features/formHeader/formHeaderSelector';

const HeaderInPreviewPage = () => {
  const formData = useSelector(selectFormFooterData);
  const {enable = false, companyName, companyPhone, companyEmail, companyLogo} = formData || {};

  function formatUSNumber(phoneNumber = '') {
    try {
      if (!(phoneNumber.length > 10)) {
        return phoneNumber;
      }

      const match = phoneNumber
        .replace(/\D+/g, '')
        .replace(/^1/, '')
        .match(/([^\d]*\d[^\d]*){1,10}$/)[0];
      const part1 = match.length > 2 ? `(${match.substring(0, 3)})` : match;
      const part2 = match.length > 3 ? ` ${match.substring(3, 6)}` : '';
      const part3 = match.length > 6 ? `-${match.substring(6, 10)}` : '';
      return `${part1}${part2}${part3}`;
    } catch (error) {
      return phoneNumber;
    }
  }

  if (!enable) return null;

  return (
    <>
      <FormHeaderWrapper
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
        className={'form_header_wrapper'}
      >
        <LogoImageBoxinForm className={'fh-logo-image-box'}>
          {companyLogo && <img src={companyLogo} alt={'logo'} />}
        </LogoImageBoxinForm>

        <HeaderRightSide spacing={0.25} alignItems={'flex-end'}>
          <Typography variant={'body1medium'} sx={{color: 'var(--fbp-theming-font-color)'}}>
            {companyName}
          </Typography>
          <Typography variant={'helperText'} sx={{color: 'var(--fbp-theming-sub-text-color)'}}>
            <HrefLink href={`tel:${companyPhone}`}>{formatUSNumber(companyPhone)}</HrefLink>
          </Typography>
          <Typography variant={'helperText'} sx={{color: 'var(--fbp-theming-sub-text-color)'}}>
            <HrefLink target={'_top'} href={`mailto:${companyEmail}`}>
              {companyEmail}
            </HrefLink>
          </Typography>
        </HeaderRightSide>
      </FormHeaderWrapper>
    </>
  );
};

export default HeaderInPreviewPage;
