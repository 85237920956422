import {styled, Typography} from '@mui/material';

export const FormText = styled(Typography)(({theme}) => ({
  fontFamily: `var(--fbp-theming-font-family) !important`,
  fontSize: '16px',
  color: 'var(--fbp-theming-text-color)',
  lineHeight: '28px',
  letterSpacing: '0.15px',
  fontWeight: '400',
  width: '100%',
  whiteSpace:'pre-wrap',
  '& p': {
    margin: 0
  }
}));
