export const FORM_SETTINGS_FORM_STATUS = {
  ENABLED: 1,
  DISABLED: 2,
  DISABLED_ON_DATE: 3,
  DISABLED_ON_SUBMISSION_LIMIT: 4,
  DISABLED_ON_DATE_AND_SUBMISSION_LIMIT: 5
};

export const FORM_SETTINGS_FORM_STATUS_LIST = [
  {
    title: 'Enabled',
    value: FORM_SETTINGS_FORM_STATUS.ENABLED
  },
  {
    title: 'Disabled',
    value: FORM_SETTINGS_FORM_STATUS.DISABLED
  },
  {
    title: 'Disable on date',
    value: FORM_SETTINGS_FORM_STATUS.DISABLED_ON_DATE
  },
  {
    title: 'Disable on submission limit',
    value: FORM_SETTINGS_FORM_STATUS.DISABLED_ON_SUBMISSION_LIMIT
  },
  {
    title: 'Disable on date and submission limit',
    value: FORM_SETTINGS_FORM_STATUS.DISABLED_ON_DATE_AND_SUBMISSION_LIMIT
  }
];

export const FORM_SETTINGS_NOTIFICATION_NONE = 0;
export const FORM_SETTINGS_NOTIFICATION_TEXT = 1;
export const FORM_SETTINGS_NOTIFICATION_EMAIL = 2;
export const FORM_SETTINGS_NOTIFICATION_BOTH = 3;

export const FORM_SETTINGS_NOTIFICATION_SETTINGS_LIST = [
  {
    title: 'None',
    value: FORM_SETTINGS_NOTIFICATION_NONE
  },
  {
    title: 'Send text notifications',
    value: FORM_SETTINGS_NOTIFICATION_TEXT
  },
  {
    title: 'Send email notifications',
    value: FORM_SETTINGS_NOTIFICATION_EMAIL
  },
  {
    title: 'Send both',
    value: FORM_SETTINGS_NOTIFICATION_BOTH
  }
];

export const THANK_YOU_PAGE_LAYOUT_DEFAULT = 1;
export const THANK_YOU_PAGE_LAYOUT_CUSTOM = 2;
export const THANK_YOU_PAGE_LAYOUT_REDIRECT = 3;

export const REDIRECT_URL_SAME_WINDOW = '_self';
export const REDIRECT_URL_NEW_WINDOW = '_blank';
export const REDIRECT_URL_OPEN_WINDOW_ARRAY = [
  {
    label: 'Same window',
    value: REDIRECT_URL_SAME_WINDOW,
    color: '#6258FF'
  },
  {
    label: 'New window',
    value: REDIRECT_URL_NEW_WINDOW,
    color: '#6258FF'
  }
];

export const FORM_SETTINGS_TYPE_HEADER = 1;
export const FORM_SETTINGS_TYPE_FOOTER = 2;
export const FORM_SETTINGS_TYPE_CONDITION = 3;
