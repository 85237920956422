import {all, call, delay, put, select, takeLatest} from 'redux-saga/effects';
import FieldMappingService from '../../../services/FieldMapping.service';
import {selectBuilderFields, selectBuilderSetting} from '../builderFields/builderSelector';
import {fetchFieldMappingSuccess} from './fieldMappingSlice';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';

function* fieldMappingWatcher() {
  yield takeLatest('fieldMapping/updateFieldMapping', updateFieldMapping);
  yield takeLatest('fieldMapping/fetchFieldMapping', fetchFieldMapping);
}

function* updateFieldMapping(action) {
  try {
    yield put(updateBuilderTime('Saving...'));

    yield delay(500);
    const {id} = yield select(selectBuilderSetting);
    const {fieldsIdMap} = yield select(selectBuilderFields);

    const payloadObj = action.payload.map(eachObj => {
      return {...eachObj, elementId: fieldsIdMap[eachObj.fieldId]};
    });

    yield call(FieldMappingService.updateFieldMapping, {
      id: id,
      mappings: JSON.stringify(payloadObj)
    });

    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* fetchFieldMapping(action) {
  try {
    const {id} = yield select(selectBuilderSetting);
    const response = yield call(FieldMappingService.getFieldMapping, {formId: id});

    if (response.success) {
      yield put(fetchFieldMappingSuccess(response.data.mappings));
    } else {
      yield put(fetchFieldMappingSuccess());
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* fieldMappingSaga() {
  yield all([fieldMappingWatcher()]);
}
