import React, { useState } from 'react';
import {Stack, Box} from '@mui/material';
import {FormLabel} from '../../../common/Form-UI/FormLabel';
import withFieldChange from '../../../hoc/withFieldChange';
import {FormInput} from '../../Builder/FormComponents/FormInput';
import HiddenFieldAlert from '../../Builder/HiddenFieldAlert';
import RequireIcon from '../../../common/Icons/RequireIcon';
import useDelayCallback from '../../../hooks/useDelayCallback';
import { useIsMounted } from '../../../hooks/IsMounted';
import Styles from './address.module.scss';

const AddressComponent = ({field, editMode, onChange}) => {

  const isMounted = useIsMounted();
  const [localValue, setLocalValue] = useState({});

  useDelayCallback(() => {
    if(isMounted) {
      onChange(localValue);
    }
  },[localValue],20);

  const handleUpdateAddress = (value) => {
    setLocalValue({...localValue, ...value})
  }

  return (
    <Box sx={{position: 'relative'}}>
      <Box>
        <Stack direction="row" alignItems="center" mb={2} className="ComponentLabel">
          <FormLabel>{field.label}{field.required && <RequireIcon />}</FormLabel>
        </Stack>
        <Box
          sx={{
            gap: '16px',
            position: 'relative',
            display: 'flex',
            flexWrap: 'wrap',
            sm: {gap: '8px'}
          }}
        >
          {field.streetAddressEnabled && (
            <Stack sx={{flex: '1 0 100%'}}>
              <FormInput
                name={field.id + 1}
                value={field.streetAddressValue}
                placeholder={field.streetAddressPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({streetAddressValue: e.target.value})}
                helperTextProps={{
                  text: field.streetAddressHelperText
                }}
                rules={field.required ? {required: 'Street address is required'} : {}}
              />
            </Stack>
          )}

          {field.streetAddress2Enabled && (
            <Stack sx={{flex: '1 0 100%'}}>
              <FormInput
                name={field.id + 2}
                value={field.streetAddress2Value}
                placeholder={field.streetAddress2Placeholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({streetAddress2Value: e.target.value})}
                helperTextProps={{
                  text: field.streetAddress2HelperText
                }}
                rules={field.required ? {required: 'Street address 2 is required'} : {}}
              />
            </Stack>
          )}

          {field.cityEnabled && (
            <Stack className={Styles.fullwidthAddress}>
              <FormInput
                name={field.id + 3}
                value={field.cityValue}
                placeholder={field.cityPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({cityValue: e.target.value})}
                helperTextProps={{
                  text: field.cityHelperText
                }}
                rules={field.required ? {required: 'City is required'} : {}}
              />
            </Stack>
          )}

          {field.stateEnabled && (
            <Stack className={Styles.fullwidthAddress}>
              <FormInput
                name={field.id + 4}
                value={field.stateValue}
                placeholder={field.statePlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({stateValue: e.target.value})}
                helperTextProps={{
                  text: field.stateHelperText
                }}
                rules={field.required ? {required: 'State is required'} : {}}
              />
            </Stack>
          )}

          {field.zipEnabled && (
            <Stack sx={{flex: '1 0 100%'}}>
              <FormInput
                name={field.id + 5}
                value={field.zipValue}
                placeholder={field.zipPlaceholder}
                readOnly={editMode || field.readOnly}
                onChange={e => handleUpdateAddress({zipValue: e.target.value})}
                helperTextProps={{
                  text: field.zipHelperText
                }}
                rules={field.required ? {required: 'Zip is required'} : {}}
              />
            </Stack>
          )}
        </Box>
      </Box>
      {field.hidden && <HiddenFieldAlert />}
    </Box>
  );
};

export default withFieldChange(AddressComponent);
