import React from 'react';
import {Box, Divider, Grid, Paper, Stack, Typography} from '@mui/material';
import useBoolean from '../../../hooks/useBoolean';
import FullPageDrawer from '../../../common/Drawer/FullPageDrawer';
import AdvanceEmbedContent from './EmbedMode/AdvanceEmbedContent';
import {EMBED_MODES} from '../../../helpers/constant/embedConstant';
import CalendarViewDayRoundedIcon from '@mui/icons-material/CalendarViewDayRounded';
import EmbedModeSingleOption from './EmbedMode/embedmodal/EmbedModeSingleOption';
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import RectangleRoundedIcon from '@mui/icons-material/RectangleRounded';
import FiberManualRecordRoundedIcon from '@mui/icons-material/FiberManualRecordRounded';
import {useDispatch} from 'react-redux';
import {setCurrentEmbedMode} from '../../../state/features/embedSettings/embedSettingsSlice';

const embedModeOptions = [
  {
    icon: <CalendarViewDayRoundedIcon />,
    mode: 'Standard',
    modeDetails: 'Present your form seamlessly as part of your website.',
    currentMode: EMBED_MODES.STANDARD
  },
  {
    icon: <SquareRoundedIcon />,
    mode: 'Full Page',
    modeDetails: 'Show people a full-page form when your site loads.',
    currentMode: EMBED_MODES.FULL_PAGE
  },
  {
    icon: <SquareRoundedIcon sx={{fontSize: '16px'}} />,
    mode: 'Pop-up',
    modeDetails:
      'Your form pops up in the center of the screen. Set custom launch options, like a time delay.',
    currentMode: EMBED_MODES.POPUP
  },
  {
    icon: <RectangleRoundedIcon sx={{transform: 'translateX(6px) rotate(90deg)'}} />,
    mode: 'Slider',
    modeDetails:
      'Your full-size form slides in from the side. Open with a button, or set custom launch options.',
    currentMode: EMBED_MODES.SLIDER
  },
  {
    icon: (
      <FiberManualRecordRoundedIcon sx={{transform: 'translate(5px, 5px)', fontSize: '14px'}} />
    ),
    mode: 'Pop-over',
    modeDetails:
      'Open as a floating popover when someone hits the button, or set custom launch options.',
    currentMode: EMBED_MODES.POPOVER
  },
  {
    icon: (
      <RectangleRoundedIcon sx={{transform: 'translateX(6px) rotate(90deg)', fontSize: '16px'}} />
    ),
    mode: 'Side Tab',
    modeDetails:
      'Open as a floating panel when someone hits the button, or set custom launch options.',
    currentMode: EMBED_MODES.SIDE_TAB
  }
];

const EmbedModes = () => {
  const {value: openDrawer, setTrue: onOpenDrawer, setFalse: onCloseDrawer} = useBoolean(false);
  const dispatch = useDispatch();

  const onChangeEmbedMode = mode => {
    dispatch(setCurrentEmbedMode(mode));
  };

  return (
    <>
      <Paper elevation={0}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          spacing={2}
          sx={{p: 3}}
        >
          <Box>
            <Typography variant={'subtitle1'}>Embed in a web page</Typography>
            <Typography variant={'body2'} color="text.secondary">
              Put your form on your website by copying and pasting this short-code.
            </Typography>
          </Box>

        </Stack>
        <Divider light />
        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}} p={3}>
          {embedModeOptions.map((embedOption, index) => {
            const {icon, mode, modeDetails, currentMode} = embedOption;
            return (
              <Grid item xs={2} sm={4} md={4} key={index}>
                <EmbedModeSingleOption
                  onClick={() => {
                    onChangeEmbedMode(currentMode);
                    onOpenDrawer();
                  }}
                  icon={icon}
                  mode={mode}
                  modeDetails={modeDetails}
                />
              </Grid>
            );
          })}
        </Grid>
      </Paper>

      <FullPageDrawer open={openDrawer} onClose={onCloseDrawer} zIndex={'1300'}>
        <AdvanceEmbedContent onClose={onCloseDrawer} />
      </FullPageDrawer>
    </>
  );
};

export default EmbedModes;
