export const FORM_SUBMISSION_PAGE_VIEW_GRID = 1;
export const FORM_SUBMISSION_PAGE_VIEW_TABLE = 2;

export const DEFAULT_TAG_COLOR = 'rgba(243, 166, 131,0.5)';
export const TAG_COLORS = [
  'rgba(243, 166, 131,0.5)',
  'rgba(247, 215, 148,0.5)',
  'rgba(119, 139, 235,0.5)',
  'rgba(231, 127, 103,0.5)',
  'rgba(207, 106, 135,0.5)',
  'rgba(120, 111, 166,0.5)',
  'rgba(248, 165, 194,0.5)',
  'rgba(99, 205, 218,0.5)',
  'rgba(234, 134, 133,0.5)',
  'rgba(89, 98, 117,0.5)',
  'rgba(184, 233, 148,0.5)',
  'rgba(56, 173, 169,0.5)',
  'rgba(229, 80, 57,0.5)',
  'rgba(255, 82, 82,0.5)',
  'rgba(112, 111, 211,0.5)',
  'rgba(52, 172, 224,0.5)',
  'rgba(247, 215, 148,0.8)',
  'rgba(119, 139, 235,0.8)',
  'rgba(231, 127, 103,0.8)',
  'rgba(207, 106, 135,0.8)',
  'rgba(248, 165, 194,0.8)',
  'rgba(99, 205, 218,0.8)',
  'rgba(234, 134, 133,0.8)',
  'rgba(184, 233, 148,0.8)',
  'rgba(56, 173, 169,0.8)',
  'rgba(255, 82, 82,0.8)',
  'rgba(112, 111, 211,0.8)',
  'rgba(52, 172, 224,0.8)'
];

export const DATA_EXPORT_FILE_TYPE = {
  CSV: 1,
  XLSX: 2,
  TXT: 3,
  PDF: 4
};

export const DATA_EXPORT_FILE_TYPE_LIST = [
  {
    label: '.csv',
    value: DATA_EXPORT_FILE_TYPE.CSV
  },
  {
    label: '.xlsx',
    value: DATA_EXPORT_FILE_TYPE.XLSX
  },
  {
    label: '.txt',
    value: DATA_EXPORT_FILE_TYPE.TXT
  },
  {
    label: '.pdf',
    value: DATA_EXPORT_FILE_TYPE.PDF
  }
];

export const S3_SIGNED_URL_ACTION = {
  VIEW: 0,
  UPLOAD: 1
};

export const FORM_DATA_EXPORT_MODULE_FORM_SUBMISSION = 1;
