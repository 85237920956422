import React, {useRef} from 'react';
import {Stack} from '@mui/material';
import RightComponentTitle from './RightComponentTitle';
import MinimalEditor from '../../../common/Editor/MinimalEditor';
import {CustomDropdown} from '../../../common/CustomDropdown';
import {USER_PERSONALIZED_MAP} from '../../../helpers/constant/CoreConstants';

const RightSideEditor = ({text, title, value = '', placeholder = '', onChangeBody = () => {}}) => {
  const editorRef = useRef();

  const handleInsertPersonalized = e => {
    editorRef.current.insertContent(e.target.value);
    setTimeout(() => {
      editorRef.current.focus();
    }, 500);
  };

  return (
    <Stack direction="column" alignItems="start" spacing={1} className="builderElementBasicStyle">
      <Stack direction="row" justifyContent="space-between" width="100%">
        <RightComponentTitle text={text} title={title} />

        <CustomDropdown
          width={'fit-content'}
          data={[{title: 'Select Personalize', value: ''}, ...USER_PERSONALIZED_MAP]}
          size="small"
          placeholder="Select Personalize"
          onChange={handleInsertPersonalized}
          wrapperClassName="formListFilter personalizedBox"
        />
      </Stack>
      <Stack direction="row" alignItems="center" sx={{width: '100%'}}>
        <MinimalEditor
          editorRef={editorRef}
          body={value}
          onChangeBody={onChangeBody}
          placeholder={placeholder}
        />
      </Stack>
    </Stack>
  );
};

export default RightSideEditor;
