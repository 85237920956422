import {all, call, put, select, takeEvery, takeLatest} from 'redux-saga/effects';
import {getBrowserName} from '../../../helpers/utils/getBrowserName';
import {getDeviceType} from '../../../helpers/utils/getDeviceType';
import {getPlatformName} from '../../../helpers/utils/getPlatformName';
import PublicPageService from '../../../services/PublicPage.service';
import {selectPublicPage} from '../builderFields/builderSelector';
import {
  fetchPublicPageDetailsFailed,
  fetchPublicPageDetailsSuccess,
  submitPublicPageFailed,
  submitPublicPageSuccess
} from './publicPageSlice';
import {
  FORM_SETTINGS_TYPE_CONDITION,
  FORM_SETTINGS_TYPE_FOOTER,
  FORM_SETTINGS_TYPE_HEADER
} from '../../../helpers/constant/formSettingsConstant';
import {getFormHeaderDataSuccess} from '../formHeader/formHeaderSlice';
import {getFormFooterDataSuccess} from '../formFooter/formFooterSlice';
import {getConditionSettingSuccess} from '../conditionSetting/conditionSettingSlice';
import responseToIframe from '../../../helpers/utils/responseToIframe';

function* publicPageWatcher() {
  yield takeLatest('publicPage/fetchPublicPageDetails', fetchPublicPageDetails);
  yield takeLatest('publicPage/submitPublicPage', submitPublicPage);
  yield takeLatest('publicPage/fetchTemplatePreviewDetails', fetchTemplatePreviewDetails);
  yield takeEvery('publicPage/submitFormAnalytics', submitFormAnalytics);
}

function* fetchPublicPageDetails(action) {
  try {
    const fullUrl = `https://forms-a5940fe4.s3.amazonaws.com/forms/${action.payload.agencyId}/${action.payload.apiKey}.json`;

    const response = yield call(PublicPageService.getFileDetails, fullUrl);
    if (!response.data) {
      yield put(fetchPublicPageDetailsFailed());
      return;
    }

    // all settings state update
    const {formSettings = []} = response.data.formInfo || {};

    for (const formSetting of formSettings) {
      if (formSetting.type === FORM_SETTINGS_TYPE_HEADER) {
        try {
          yield put(getFormHeaderDataSuccess(JSON.parse(formSetting.settings)));
        } catch (err) {
          console.log(err);
        }
      }
      if (formSetting.type === FORM_SETTINGS_TYPE_FOOTER) {
        try {
          yield put(
            getFormFooterDataSuccess(JSON.parse(formSetting.settings.replaceAll('\n', '<br/>')))
          );
        } catch (err) {
          console.log(err);
        }
      }
      if (formSetting.type === FORM_SETTINGS_TYPE_CONDITION) {
        try {
          yield put(getConditionSettingSuccess(JSON.parse(formSetting.settings)));
        } catch (err) {
          console.log(err);
        }
      }
    }

    yield put(fetchPublicPageDetailsSuccess(response.data));
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* fetchTemplatePreviewDetails(action) {
  try {
    let response = yield call(PublicPageService.getTemplateDetails, action.payload.templateId);
    if (!response.success) {
      yield put(fetchPublicPageDetailsFailed());
      return;
    }

    response = yield call(PublicPageService.getFileDetails, response.data.fileUrl);

    if (!response.data) {
      yield put(fetchPublicPageDetailsFailed());
      return;
    }

    yield put(fetchPublicPageDetailsSuccess(response.data));
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* submitPublicPage(action) {
  try {
    const {apiKey, agencyId} = yield select(selectPublicPage);
    const screenSize = window.screen.width + 'x' + window.screen.height;
    const device = getDeviceType();
    const browser = getBrowserName();
    const platform = getPlatformName();

    let response = yield call(PublicPageService.submitPublicPage, {
      apiKey,
      agencyId,
      submission: action.payload,
      formAnalytics: {
        screenSize,
        device,
        browser,
        platform
      }
    });
    responseToIframe();
    if (!response?.success) {
      yield put(submitPublicPageFailed());
      return;
    }
    yield put(submitPublicPageSuccess(response.data));
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* submitFormAnalytics(action) {
  try {
    const screenSize = window.screen.width + 'x' + window.screen.height;
    const device = getDeviceType();
    const browser = getBrowserName();
    const platform = getPlatformName();

    yield call(PublicPageService.submitFormAnalytics, {
      apiKey: action.payload.apiKey,
      agencyId: action.payload.agencyId,
      formAnalytics: {
        screenSize,
        device,
        browser,
        platform
      }
    });
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* publicPageSaga() {
  yield all([publicPageWatcher()]);
}
