import {all, put, call} from 'redux-saga/effects';
import {takeLatest} from "@redux-saga/core/effects";
import {
  getFormSettingFailed,
  getFormSettingSuccess,
  updateFormSettingFailed,
  updateFormSettingSuccess
} from './formSettingSlice';
import {showErrorSnackbar} from '../snackbar/snackbarSlice';
import FormSettingService from '../../../services/FormSetting.service';
import {updateBuilderTime} from '../builderSetting/builderSettingSlice';

function* formSettingWatcher() {
  yield takeLatest('formSetting/getFormSetting', getFormSettingSaga);
  yield takeLatest('formSetting/updateFormSetting', updateFormSettingSaga);
}

function* getFormSettingSaga(action) {
  try {
    const response = yield call(FormSettingService.getFormSetting, action.payload);

    if (response.success) {
      yield put(getFormSettingSuccess(response.data));
    } else {
      console.log('failed saga')
      yield put(getFormSettingFailed(response.message));
    }
  } catch (err) {
    console.log('Error: ', err);
  }
}

function* updateFormSettingSaga(action) {
  try {
    yield put(updateBuilderTime('Saving...'));
    const response = yield call(FormSettingService.updateFormSetting, action.payload);

    if (response.success) {
      const settingsData = JSON.parse(response.data.formSettings);
      yield put(updateFormSettingSuccess(settingsData));
    } else {
      yield put(updateFormSettingFailed(response.message));
      yield put(showErrorSnackbar(response.message));
    }
    
    yield put(
      updateBuilderTime(
        'All changes saved at ' + new Date().toLocaleString([], {timeStyle: 'short'})
      )
    );
  } catch (err) {
    console.log('Error: ', err);
  }
}

export default function* formSettingSaga() {
  yield all([formSettingWatcher()]);
}
