import React from 'react';
import {Editor} from '@tinymce/tinymce-react';

const MinimalEditor = ({body = '', onChangeBody = () => {}, editorRef, placeholder = ''}) => (
  <Editor
    apiKey="bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr"
    onInit={(evt, editor) => {
      if (editorRef) {
        editorRef.current = editor;
      }
    }}
    value={body}
    cloudChannel="5-dev"
    textareaName="minimal_editor"
    init={{
      width: '100%',
      height: 300,
      auto_focus: true,
      convert_urls: false,
      menubar: false,
      plugins: 'link',
      toolbar:
        'bold italic forecolor backcolor link | fontsizeselect bullist numlist outdent indent | alignleft aligncenter alignright alignjustify | lineheight | removeformat',
      content_style: 'body { font-size: 8pt; line-height: 1;} p { margin: 0px; } ',
      placeholder: placeholder,
      fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
      line_height_formats: '1 1.2 1.4 1.6 2'
    }}
    onEditorChange={onChangeBody}
  />
);

export default MinimalEditor;
