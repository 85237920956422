import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {Box, useTheme} from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import Theming from '../Builder/Theming/Theming';
import PublicPageContent from './PublicPageContent';
import SeoSettings from '../../common/SeoSettings';
import {
  fetchPublicPageDetails,
  submitFormAnalytics
} from '../../state/features/publicPage/publicPageSlice';
import PageNotFound from './PageNotFound';
import CardViewPublicPageContent from './CardViewPublicPageContent';
import {FORM_STATUS} from '../../helpers/constant/formConstant';
import PublicPageLoader from '../../common/Loader/PublicPageLoader';
import {decryptUrlValue} from '../../helpers/utils/decryptUrlValue';
import Styles from '../Builder/BuilderIndex.module.scss';

const PublicPage = () => {
  const dispatch = useDispatch();
  const {base64Key} = useParams();
  const themes = useTheme();
  const {isPageLoading, pageLoadError, theme, title, status, seoSettings, favIcon} = useSelector(
    state => state.publicPage
  );

  useEffect(() => {
    const {apiKey, agencyId} = decryptUrlValue(base64Key);
    const submitData = {apiKey, agencyId};
    dispatch(fetchPublicPageDetails(submitData));
    dispatch(submitFormAnalytics(submitData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isPageLoading) {
    return <PublicPageLoader />;
  }

  if (pageLoadError || status !== FORM_STATUS.PUBLISHED) {
    return (
      <PageNotFound
        icon={<InfoIcon sx={{fontSize: 80, color: `${themes.palette.other.skylight}`}} />}
        text="This form is currently unavailable!"
      />
    );
  }

  return (
    <Box className={Styles.fbpFormBuilderPreviewWrapper}>
      <SeoSettings
        title={title}
        favIcon={favIcon}
        description={seoSettings.description}
        keywords={seoSettings.keywords}
        author={seoSettings.author}
        robots={seoSettings.robots}
      />
      <Box className={Styles.fbpFormBuilderBodyWrapper}>
        <Box className={Styles.fbpFormBuilderBodyInner}>
          <Box
            className={`${Styles.fbpFormBuilderScrollable} fbp_has_scroll ${
              theme.layout === 'card' ? Styles.cardViewBodyWrapper : ''
            }`}
          >
            <Theming theme={theme} />
            <Box sx={{width: '100%', height: '100vh'}}>
              {theme.layout === 'classic' ? (
                <Box className={Styles.publicPageContentWrp}>
                  <PublicPageContent theme={theme} className={'forPublicPage'} />
                </Box>
              ) : (
                <CardViewPublicPageContent theme={theme} />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicPage;
